/* stylelint-disable selector-list-comma-newline-after */
a.sbui-typography-link {
    @apply underline;
    @apply text-gray-500;
}

header nav ul.dropdown {
   @apply absolute right-0 bg-white text-gray-500 mt-1 p-1 shadow-lg z-50 rounded;
}
header nav ul.dropdown li.dropdown-item a,
header nav ul.dropdown li.dropdown-item button {
    @apply py-2 px-4 block whitespace-nowrap;
}
header nav ul.dropdown li.dropdown-item a:hover,
header nav ul.dropdown li.dropdown-item button:hover {
    @apply bg-gray-100;
}

form .form-row {
    @apply flex flex-wrap mb-4;
}
form .form-group {}
form .form-group p {
    @apply mb-0;
}
form .form-hint {
    @apply text-xs -mt-1;
}
form .form-hint.error {
    @apply text-red-500 italic;
}

form .react-select {
    @apply mb-3;
}

form .react-select__input:focus {
    @apply shadow-none ring-0;
}

.sbui-input-container .sbui-input--with-icon {
    @apply pl-10;
}

a[href='#auth-sign-in'] ~ .sbui-typography-text,
a[href='#auth-sign-up'] ~ .sbui-typography-text {
    @apply bg-green-500 text-white p-3;
}
a[href='#auth-sign-in'] ~ .sbui-typography-text.sbui-typography-text-danger,
a[href='#auth-sign-up'] ~ .sbui-typography-text.sbui-typography-text-danger {
    @apply bg-red-500;
}

.sbui-checkbox-container {
    @apply w-full md:w-auto items-center;
}

.sbui-checkbox-container .sbui-checkbox__label-container label {
    @apply mb-0;
}

.btn {
    @apply rounded no-underline flex inline-flex items-center justify-center font-medium space-x-2;
}
.btn:disabled {
    @apply bg-opacity-70 cursor-default;
}
.btn-sm {
    @apply py-1 px-3 text-sm;
}
.btn-xs {
    @apply py-0.5 px-2 text-xs;
}
.sbui-btn-primary,
.btn-solid {
    @apply rounded-lg shadow-sm py-3 px-5 border border-opacity-10;
}
.sbui-btn-primary:not(:disabled):hover,
.btn-solid:not(:disabled):hover {
    @apply bg-opacity-80 border-opacity-80 shadow-lg;
}
.btn-ink {
    @apply text-white bg-ink border-ink;
}
.btn-ink-100 {
    @apply text-white bg-ink-100 border-ink-100;
}
.btn-danger {
    @apply text-white bg-red-500 border-red-500;
}
.btn-outline {
    @apply bg-transparent border border-ink text-ink;
}
.btn-outline:hover {
    @apply bg-ink text-white;
}
.btn-outline:focus {
    @apply outline-none ring-1 border-ink-300;
}
.btn-block {
    @apply w-full sm:w-auto;
}

.card {
    @apply rounded border shadow-sm bg-white;
}

.card input {
    @apply bg-gray-100 border-gray-100;
}

.card input:focus {
    @apply bg-gray-100 ring-gray-300 border-gray-300
}

.chip {
    @apply inline-block rounded-full bg-gray-100 border-gray-200 border px-2 py-0.5 text-xs;
}

.chip a {
    @apply no-underline;
}

:not(.grid) > .card .titlecard h3 {
    @apply text-5xl leading-snug;
}

.loader {
    @apply rounded-full border-ink;
    border-top-color: #D1D5DB;
    -webkit-animation: spinner 1.5s linear infinite;
    animation: spinner 1.5s linear infinite;
}

.loader.inverse {
    @apply rounded-full border-gray-300;
    border-top-color: transparent;
}

@-webkit-keyframes spinner {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}

@keyframes spinner {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
