@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    body {
        @apply text-gray-800;
    }

    article {
        @apply p-4 sm:px-6 lg:px-16;
    }

    h1, h2, h3, h4, h5, h6 {
        @apply font-playfair text-gray-800 my-3;
    }
    h1 {
        @apply text-3xl;
    }
    h2 {
        @apply text-2xl;
    }
    h3 {
        @apply text-xl;
    }
    h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
        color: inherit;
    }

    p {
        @apply mb-4;
    }

    a {
        @apply underline;
        @apply text-gray-500;
    }
    a:hover {
        @apply no-underline;
    }

    form, .form {
        @apply w-full max-w-lg my-10 mx-auto px-4 md:px-0;
    }

    label {
        @apply block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2;
    }

    [type='text'],
    [type='email'],
    [type='url'],
    [type='password'],
    [type='number'],
    [type='date'],
    [type='datetime-local'],
    [type='month'],
    [type='search'],
    [type='tel'],
    [type='time'],
    [type='week'],
    [multiple],
    textarea,
    select {
        @apply appearance-none block w-full bg-white text-gray-700 border border-white rounded py-3 px-4 mb-2 leading-tight shadow-sm;
    }

    [type='text']:focus,
    [type='email']:focus,
    [type='url']:focus,
    [type='password']:focus,
    [type='number']:focus,
    [type='date']:focus,
    [type='datetime-local']:focus,
    [type='month']:focus,
    [type='search']:focus,
    [type='tel']:focus,
    [type='time']:focus,
    [type='week']:focus,
    [multiple]:focus,
    textarea:focus,
    select:focus {
        @apply bg-white ring-gray-300 border-gray-300 shadow-none;
    }

    [type='text']:read-only,
    [type='email']:read-only,
    [type='url']:read-only,
    [type='password']:read-only,
    [type='number']:read-only,
    [type='date']:read-only,
    [type='datetime-local']:read-only,
    [type='month']:read-only,
    [type='search']:read-only,
    [type='tel']:read-only,
    [type='time']:read-only,
    [type='week']:read-only,
    [multiple]:read-only,
    textarea:read-only,
    select:read-only {
        @apply cursor-default bg-transparent border-transparent shadow-none px-0;
    }

    [type='text']:read-only:focus,
    [type='email']:read-only:focus,
    [type='url']:read-only:focus,
    [type='password']:read-only:focus,
    [type='number']:read-only:focus,
    [type='date']:read-only:focus,
    [type='datetime-local']:read-only:focus,
    [type='month']:read-only:focus,
    [type='search']:read-only:focus,
    [type='tel']:read-only:focus,
    [type='time']:read-only:focus,
    [type='week']:read-only:focus,
    [multiple]:read-only:focus,
    textarea:read-only:focus,
    select:read-only:focus {
        @apply border-transparent;
        box-shadow: none;
    }

    [type="checkbox"],
    [type="radio"] {
    }

    [type="checkbox"]:focus,
    [type="radio"]:focus {
    }
}
